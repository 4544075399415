import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from '../../helper/PreviewCompatibleImage'
import { LangContext } from '../../context/langContext'
import lang from '../../helper/lang.json'
const ServicesRoll = props => {
  const { data } = props
  const { edges: posts } = data.allMarkdownRemark
  const { state } = useContext(LangContext)
  const posts2 = posts.filter(elem => {
    return elem.node.frontmatter.lang === state.lang
  })
  return (
    <div className="columns is-multiline">
      {posts2 &&
        posts2.map(({ node: post }) => (
          <div className="is-parent column is-6" key={post.id}>
            <article
              className={`blog-list-item tile is-child box  ${
                post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
            >
              <header>
                {post.frontmatter.image ? (
                  <div className="featured-thumbnail">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: post.frontmatter.image,
                        alt: `featured image thumbnail for post ${post.title}`,
                      }}
                    />
                  </div>
                ) : null}
                <p className="post-meta">
                  <Link
                    className="title has-text-primary is-size-4"
                    to={`${lang[state.lang].slug +
                      post.fields.slug.slice(1, -3)}`}
                  >
                    {post.frontmatter.title}
                  </Link>
                  <span className="subtitle is-size-5 is-block">
                    {/*post.frontmatter.date*/}
                  </span>
                </p>
              </header>
              <p>
                {post.excerpt}
                <br />
                <br />
                <Link
                  className="button"
                  to={`${lang[state.lang].slug +
                    post.fields.slug.slice(1, -3)}`}
                >
                  {`${lang[state.lang].more}`}
                </Link>
              </p>
            </article>
          </div>
        ))}
    </div>
  )
}

ServicesRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query ServicesRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "service-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                lang
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                image {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                      src
                      srcSet
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ServicesRoll data={data} count={count} />}
  />
)
